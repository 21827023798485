import React, { useContext } from 'react';
import { Select, MenuItem, FormControl, InputLabel } from '@material-ui/core';
import { DashboardContext } from "./v3Context.js";
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  CollectionSelectionDropDown: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    border: 'none',
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(0.5),
    margin: theme.spacing(0.5),
    cursor: 'pointer',
    textAlign: 'center',
    boxShadow: theme.shadows[5],
    backgroundColor: theme.palette.type === 'dark' ? theme.palette.grey[800] : theme.palette.grey[200],
  },
  CollectionSelectionDropDownSelect: {
    minWidth: 200,
    padding: theme.spacing(1.5), // Add padding to the Select component
  },
  inputLabel: {
    paddingLeft: theme.spacing(1), // Add padding to the left of the InputLabel
  },
}));

const LeagueSelect = () => {
  const classes = useStyles();
  const { leagues, activeLeague, setActiveLeague } = useContext(DashboardContext);



  const handleChange = (event) => {
    setActiveLeague(event.target.value);
  };

  if (leagues) {
  
  return (
    <FormControl className={classes.CollectionSelectionDropDown}>
    <InputLabel id="league-select-label" className={classes.inputLabel}>Select A League</InputLabel>
    <Select
      labelId="league-select-label"
      value={activeLeague || ''}
      onChange={handleChange}
      displayEmpty
      className={classes.CollectionSelectionDropDownSelect}
    >
      {leagues.map((league, index) => (
        <MenuItem key={index} value={league}>
          {league}
        </MenuItem>
      ))}
    </Select>
  </FormControl>
  );
} else {
  return null;
}
};



export default LeagueSelect;
