import React, { useEffect, useState,useContext } from 'react';
import { DashboardContext } from './v3Context.js';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import {
  Typography, IconButton, List, ListItem, ListItemText, Box
} from '@material-ui/core';
import { Folder, InsertDriveFile, ArrowBack } from '@material-ui/icons';
import SelectorModule from './SelectorModule.jsx';

const useStyles = makeStyles((theme) => ({
  dataExplorerContainer: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    height: '100%',
    margin: theme.spacing(0),
    padding: theme.spacing(0),
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  
  fileTree: {
    width: '30%',
    margin: theme.spacing(0),
    borderRight: `1px solid ${theme.palette.divider}`,
    height: '100%',
    overflow: 'auto',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      height: '40%',
      borderRight: 'none',
      borderBottom: `1px solid ${theme.palette.divider}`,
    },
  },
  fileTreeList: {
    margin: theme.spacing(0),
    padding: theme.spacing(0),
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
  },
  fileContent: {
    flex: 1,
    padding: theme.spacing(0),
    display: 'flex',
    flexDirection: 'column',
    overflow: 'auto',
  },
  breadcrumb: {
    margin: theme.spacing(0),
  },
  viewToggle: {
    marginLeft: 'auto',
  },
  gridView: {
    display: 'flex',
    flexWrap: 'wrap',
    '& > *': {
      width: '100px',
      margin: theme.spacing(0),
    },
  },
  listView: {
    width: '100%',
  },
  icon: {
    marginRight: theme.spacing(1),
  },
  listItem: {
    justifyContent: 'flex-start',
    margin: theme.spacing(0),
    padding: theme.spacing(0),
  },
}));

const DataExplorer = () => {
  const { fetchFromAPI, dictionary, activeLeague } = useContext(DashboardContext);
  const classes = useStyles();
  const [selectedFile, setSelectedFile] = useState(null);
  const [path, setPath] = useState([]);
  const [files, setFiles] = useState([])

 useEffect(() => {
    setFiles(dictionary)
 }, [dictionary])

  const handleItemClick = async (event, item) => {
    if (item.type === 'folder') {
      setPath([...path, item.name]);
      setFiles(item.contents);
    } else if (item.type === 'file') {
      let stat = await fetchFromAPI('v3/statDefinition', 'POST', { league: activeLeague, statName: item.statName, ref: item.ref })
      setSelectedFile(stat)
    }
    
  };
    return (
    <div className={classes.dataExplorerContainer}>
      <div className={classes.fileTree}>
        <List className={classes.fileTreeList}>
          {files.map((file, index) => (
            <ListItem button 
              key={index}
              onMouseDown={(e) => e.stopPropagation()}
              onClick={(e) => {
                  handleItemClick(e, file)}
                }
              className={classes.listItem}>
              {file.type === 'folder' ? <Folder className={classes.icon} /> : <InsertDriveFile className={classes.icon} />}
              <ListItemText primary={file.type === 'folder' ? file.name : file.statName} />
            </ListItem>
          ))}
        </List>
      </div>
      <div className={classes.fileContent}>
      
            { selectedFile ?
             <Box pl={2} pt={0} pb={0} pr={0}>
              <Box position="sticky"
                top={0}
                zIndex={1000}
                borderRadius={4}
                bgcolor="background.paper"
                p={0}
                display="flex"              // Add this line
                flexDirection="row"         // Add this line
                alignItems="center"         // Optionally, center-align items vertically
                > 
                {/* Icon to go back one level */}
                <IconButton 
                  onMouseDown={(e) => e.stopPropagation()}
                  onClick={() => {setFiles(dictionary)}}> 
                  <ArrowBack />
                </IconButton>
                <Typography variant="h5">{selectedFile.statName}</Typography>
              </Box>
              <SelectorModule file={selectedFile} filters={selectedFile.Filters}/>
              <Box pl={2} pt={1} pb={0} pr={0}>
                <Typography variant="body1">{selectedFile.Description}</Typography>
                <Typography variant="h6">Explanation</Typography>
                <Typography variant="body1">{selectedFile['NoviceExplanation']}</Typography>
                <Typography variant="h6">Sports Bettor's Insight</Typography>
                <Typography variant="body1">{selectedFile[`BettorInsight`]}</Typography>
              </Box>
            </Box> : <Box mt={2}>
                <Typography variant="h6" align="center">
                  Select A Stat For More Selection Options
                </Typography>
            </Box>
            }
      </div>
    </div>
  );
};

DataExplorer.propTypes = {
  initialFiles: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      type: PropTypes.oneOf(['file', 'folder']).isRequired,
      contents: PropTypes.array,
    })
  ),
};

export default DataExplorer;
