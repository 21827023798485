import React, { useEffect, useContext, useState } from 'react';
import { DashboardContext } from './v3Context.js';
import { makeStyles } from '@material-ui/core/styles';
import { Card, CardContent, Typography, List, ListItem, Divider, Grid } from '@material-ui/core';

// Custom styles for the component
const useStyles = makeStyles((theme) => ({
  root: {
},
componentContainer: {
        flexDirection: 'row', // Default to row
        justifyContent: 'space-between',
        flexWrap: 'wrap', // Allow elements to wrap if needed
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column', // Switch to column layout on small screens
          },
      display: 'flex',
    padding: theme.spacing(0),
    width: '100%',
    height: '100%',
    overflow: 'auto',
  },
  card: {
    margin: theme.spacing(.5),
    padding: theme.spacing(.5),
    backgroundColor: theme.palette.background.paper,
    flex: '1 1 48%', // Take 48% of width to fit 2 cards side-by-side
    [theme.breakpoints.down('sm')]: {
      flex: '1 1 100%', // On small screens, make the card take full width
    },
    maxHeight: '400px', // Set a max height for the card
    overflowY: 'auto', // Enable scrolling if content exceeds height
  },
  teamHeader: {
    marginBottom: theme.spacing(.5),
    textAlign: 'center',
    fontWeight: 'bold',
  },
  transactionItem: {
    padding: theme.spacing(.5),
  },
  divider: {
    margin: theme.spacing(.5, 0),
  }
}));

const Transactions = () => {
  const classes = useStyles();
  const { game, fetchFromAPI } = useContext(DashboardContext);
  
  const [homeTransactions, setHomeTransactions] = useState([]);
  const [awayTransactions, setAwayTransactions] = useState([]);
  const [homeTeam, setHomeTeam] = useState(null);
  const [awayTeam, setAwayTeam] = useState(null);

  useEffect(() => {
    if (game) {
      let home = game.home.teamName;
      let away = game.visitor.teamName;
      let league = game.home.league;

      let data = { home, away, league };

      fetchFromAPI('v3/transactions', 'POST', data).then(response => {
        setHomeTransactions(response[3]);
        setAwayTransactions(response[1]);
        setHomeTeam(response[2]);
        setAwayTeam(response[0]);
      });
    }
  }, [game, fetchFromAPI]);
  
  if (game) {
    return (
      <div className={classes.componentContainer}>
        {/* Visitor (Away Team) Transactions */}
        <Card className={classes.card}>
          <CardContent>
            <Typography variant="subtitle1" className={classes.teamHeader}>
              Visitor: {awayTeam}
            </Typography>
            <List>
              {awayTransactions.map((transaction, index) => (
                <React.Fragment key={index}>
                  <ListItem className={classes.transactionItem}>
                    <Typography variant="body2">{new Date(transaction.date).toLocaleString() } - {transaction.transaction}</Typography>
                  </ListItem>
                  {index < awayTransactions.length - 1 && <Divider />}
                </React.Fragment>
              ))}
            </List>
          </CardContent>
        </Card>

        {/* Home Team Transactions */}
        <Card className={classes.card}>
          <CardContent>
            <Typography variant="subtitle1" className={classes.teamHeader}>
              Home: {homeTeam}
            </Typography>
            <List>
              {homeTransactions.map((transaction, index) => (
                <React.Fragment key={index}>
                  <ListItem className={classes.transactionItem}>
                    <Typography variant="body2">{new Date(transaction.date).toLocaleString()} - {transaction.transaction}</Typography>
                  </ListItem>
                  {index < homeTransactions.length - 1 && <Divider />}
                </React.Fragment>
              ))}
            </List>
          </CardContent>
        </Card>
      </div>
    );
  } else {
    return <div>Loading...</div>;
  }
};

export default Transactions;
