import React, { useContext, useState, useEffect } from 'react';
import { DashboardContext } from './v3Context.js';
import { makeStyles } from '@material-ui/core/styles';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Tabs, Tab, Typography } from '@material-ui/core';
import { format, isToday, addDays, isSameDay } from 'date-fns';
import { toZonedTime } from 'date-fns-tz';

const useStyles = makeStyles((theme) => ({
  componentContainer: {
    padding: theme.spacing(0),
    width: '100%',
    height: '100%',
    overflow: 'auto',
  },
  tableContainer: {
    width: '100%',
    padding: theme.spacing(0),
  },
  stickyHeader: {
    padding: theme.spacing(0),
    position: 'sticky',
    top: 0,
    backgroundColor: theme.palette.background.paper,
    zIndex: 1,
  },
  eventRow: {
    cursor: 'pointer',
    padding: theme.spacing(0),
    // paddingRight: theme.spacing(2),
    '&:hover': {
      backgroundColor: theme.palette.action.hover,
    },
  },
  
  selectedRow: {
    backgroundColor: theme.palette.action.focus,
  },


  score: {
    margin: theme.spacing(0),
    padding: theme.spacing(0),
    // paddingRight: theme.spacing(2),
    // paddingLeft: theme.spacing(2),
    textAlign: 'center',
  },
  cell: {
    padding: theme.spacing(.5),
    // paddingRight: theme.spacing(2),
    // paddingLeft: theme.spacing(2),
    textAlign: 'center',
  },
  vcell: {
    padding: theme.spacing(.5),
    textAlign: 'left',
    paddingRight: theme.spacing(2),
    
  },
  hcell: {
    padding: theme.spacing(.5),
    // paddingRight: theme.spacing(2),
    textAlign: 'right',
    //center vertically
  },
  tabs: {
    borderBottom: `1px 1px 1px 1px solid ${theme.palette.primary}`,
    marginBottom: theme.spacing(2),
  },
}));

export default function GameSchedule() {
  const classes = useStyles();
  
  const { fetchFromAPI, activeLeague, setGame, game } = useContext(DashboardContext);
  // const [isDragging, setIsDragging] = useState(false);
  const [group, setGroup] = useState('Ongoing')
  const [gameList, setGameList] = useState(null);

  useEffect(async () => {
    if (activeLeague) {
      let response = await fetchFromAPI('v3/games', 'POST', { league: activeLeague });
      setGameList(response);
    }
  }, [activeLeague]);

  const handleGroupChange = (event, newValue) => {
    event.stopPropagation();
    setGroup(newValue);
  };

  const formatEventTime = (eventTime) => {
    const eventDate = new Date(eventTime);
    const timeZone = 'America/New_York'; // EST/EDT timezone
    const zonedDate = toZonedTime(eventDate, timeZone);
    const today = new Date();
    const tomorrow = addDays(today, 1);
    if (isToday(zonedDate)) {
      return `Today, ${format(zonedDate, 'hh:mm a')} EST`;
    } else if (isSameDay(zonedDate, tomorrow)) {
      return `Tomorrow, ${format(zonedDate, 'hh:mm a')} EST`;
    } else {
      return `${format(zonedDate, 'MMMM d, h:mm')} pm`;
    }
  };

  const handleItemClick = (event, item) => {
    event.stopPropagation();
    setGame(item);
  };
  
  if (!gameList) {
    return <div>Loading...</div>;
  }
  
  return (
    <div className={classes.componentContainer}>
      {/* <Typography variant="subtitle1" align="left">Game Schedule - {activeLeague}</Typography> */}
      <Tabs
        value={group}
        onMouseDown={(e) => e.stopPropagation()}
        onChange={handleGroupChange}
        indicatorColor="primary"
        textColor="primary"
        variant="fullWidth"
        className={classes.tabs}
      >
        <Tab label={`Upcoming (${gameList['Upcoming'].length || 0})`} value="Upcoming" />
        <Tab label={`Playing Now (${gameList['Ongoing'].length || 0})`} value="Ongoing" />
        <Tab label={`Completed (${gameList['Completed'].length || 0})`} value="Completed" />
      </Tabs>
      <TableContainer component={Paper} className={classes.tableContainer}>
        <Table stickyHeader={true}>
          <TableHead>
            <TableRow className={classes.stickyHeader}>
              <TableCell className={classes.cell}>Time</TableCell>
              <TableCell colSpan={2} className={classes.cell}>Visitor</TableCell>
              <TableCell className={classes.cell}></TableCell>
              <TableCell colSpan={2} className={classes.cell}>Home</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {
              !activeLeague ? (
                <TableRow>
                  <TableCell colSpan={6}>
                    Select a league to view games
                  </TableCell>
                </TableRow>
              ) : ( 
                gameList[group].map((g, index) => {
                  let selected = false
                  if (game) {
                    selected = game.oddsAPIId ? game.oddsAPIId === g.oddsAPIId : g.id === game.id;
                  }
                  let vteam = group === 'Completed' ? g.visitorTeamDetails.teamName : g.visitor.teamName;
                  let hteam = group === 'Completed' ? g.homeTeamDetails.teamName : g.home.teamName;
                  let gameTime = group === 'Completed' ? g.gamedate : g.eventTime;
                  // let homeIcon = g.HomeTeam.logoURL ? <img src={g.HomeTeam.logoURL} alt={g.HomeTeam.fullName} width="24" height="24" /> : null;
                  // let visitorIcon = g.VisitorTeam.logoURL ? <img src={g.VisitorTeam.logoURL} alt={g.VisitorTeam.fullName} width="24" height="24" /> : null;
                  return (
                  <TableRow key={index} className={`${classes.eventRow} ${selected ? classes.selectedRow : ''}`} onMouseDown={(e) => e.stopPropagation()} onClick={(event) => handleItemClick(event, g)}>
                  <TableCell className={classes.cell}>{formatEventTime(gameTime)}</TableCell>
                     <TableCell colSpan={1} className={classes.hcell}>{vteam}</TableCell>
                    
                     {/* Visitor Score with Icon */}
                     <TableCell className={classes.cell}>
                       <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                         {/* <span style={{ marginRight: '8px' }}>{visitorIcon}</span> */}
                         <Typography className={classes.score} variant="h6">{g.scoreVis}</Typography>
                       </div>
                     </TableCell>
                    
                     <TableCell className={classes.cell}>@</TableCell>
                    
                     {/* Home Score with Icon */}
                     <TableCell className={classes.cell}>
                       <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                         {/* <Typography className={classes.score} variant="h6">{g.scoreHome}</Typography> */}
                         {/* <span style={{ marginLeft: '8px' }}>{homeIcon}</span> */}
                       </div>
                     </TableCell>
                    
                     <TableCell colSpan={3} className={classes.vcell} >{hteam}</TableCell>
                   </TableRow>
                  
                )}
              )
            )
            }
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}
