import React, { useState, useContext, useEffect } from 'react';
import { Box, Typography, TextField } from '@material-ui/core';
import { DashboardContext } from './v3Context.js';

const EditableTypography = ({listname, description}) => {
  const [isEditing, setIsEditing] = useState({ listname: false, description: false });
  const [values, setValues] = useState({
    listname: listname,
    description: description,  // Placeholder for the activeList.description
  });
  
  const { setMyLists, list, sport } = useContext(DashboardContext);

  useEffect(() => {
    setValues({
      listname: listname,
      description: description,
    });
  }, [list]);
  const handleEdit = (field) => {
    setIsEditing({ ...isEditing, [field]: true });
  };

  const handleSave = (field) => {
    setIsEditing({ ...isEditing, [field]: false });
    setMyLists((prev) => {
        const newLists = [...prev]
        //get the index from newLists where sport = sport
        let index = newLists.findIndex(list => list.sport === sport)
        newLists[index].contents[list].listname = values.listname === '' ? 'Default List' : values.listname
        newLists[index].contents[list].description = values.description
        return newLists;
      });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setValues({ ...values, [name]: value });
  };

  return (
    <Box p={1}>
      {/* Listname Typography / TextField */}
      {isEditing.listname ? (
        <TextField
          name="listname"
          value={values.listname}
          onChange={handleChange}
          onMouseDown={(e) => e.stopPropagation()}  // Prevents the click event from bubbling up to the parent
          onBlur={() => handleSave('listname')}  // Save on blur
          onKeyDown={(e) => e.key === 'Enter' && handleSave('listname')}  // Save on Enter key
          autoFocus
          fullWidth
          />
        ) : (
            <Typography variant='subtitle2' align='left' onMouseDown={(e) => e.stopPropagation()} onClick={() => handleEdit('listname')}>
          {values.listname}
        </Typography>
      )}

      {/* Description Typography / TextField */}
      {isEditing.description ? (
          <TextField
          name="description"
          value={values.description}
          onChange={handleChange}
          onMouseDown={(e) => e.stopPropagation()}  // Prevents the click event from bubbling up to the parent
          onBlur={() => handleSave('description')}  // Save on blur
          onKeyDown={(e) => e.key === 'Enter' && handleSave('description')}  // Save on Enter key
          autoFocus
          fullWidth
        />
      ) : (
        <Typography variant='body2' align='left' onMouseDown={(e) => e.stopPropagation()} onClick={() => handleEdit('description')}>
          {values.description}
        </Typography>
      )}
    </Box>
  );
};

export default EditableTypography;
