import React, { createContext, useContext, useState, useEffect, useCallback } from 'react';
import { useAuth } from "../util/auth.js"
import _ from 'lodash'
import { se } from 'date-fns/locale';




export const DashboardContext = createContext();

// Custom hook to facilitate accessing context data
export const useDashboard = () => useContext(DashboardContext);

const apiUrl = process.env.REACT_APP_API_URL;
const freeRows = 4

export const DataProvider = ({ children }) => {
  const [leagues, setLeagues] = useState([]);
  const [sport, setSport] = useState(null)
  const [activeLeague, setActiveLeague] = useState(null)
  const [dictionary, setDictionary] = useState([])
  const [game, setGame] = useState(null)
  const [gameData, setGameData] = useState(null)
  const [myLists, setMyLists] = useState([
    {
      sport: 'Baseball',
      contents: [
        {
          listname: 'My First Baseball Collection',
          sport: 'Baseball',
          stats: [
          ],
          description: 'A Simple Collection of Baseball Stats. Add, Remove, and Reorder this list, or better yet, create a new collection your own!',
        },
        {
          listname: 'My Second Baseball Collection',
          sport: 'Baseball',
          stats: [
          ],
          description: 'A Simple Collection of Baseball Stats. Add, Remove, and Reorder this list, or better yet, create a new collection your own!',
        },
      ],  
    },
    {
      sport: 'Basketball',
      contents: [
        {
          listname: 'My First Basketball Collection',
          sport: 'Basketball',
          stats: [
          ],
          description: 'A Simple Collection of Basketball Stats. Add, Remove, and Reorder this list, or better yet, create a new collection your own!',
        },
        {
          listname: 'My Second Basketball Collection',
          sport: 'Basketball',
          stats: [
          ],
          description: 'A Simple Collection of Basketball Stats. Add, Remove, and Reorder this list, or better yet, create a new collection your own!',
        },
      ],  
    },
    {
      sport: 'Football',
      contents: [
        {
          listname: 'My First Football Collection',
          sport: 'Football',
          stats: [
          ],
          description: 'A Simple Collection of Football Stats. Add, Remove, and Reorder this list, or better yet, create a new collection your own!',
        },
        {
          listname: 'My Second Football Collection',
          sport: 'Football',
          stats: [
          ],
          description: 'A Simple Collection of Football Stats. Add, Remove, and Reorder this list, or better yet, create a new collection your own!',
        },
      ],  
    },

  ]
  )
  const [list, setList] = useState(0);
  const [chartDataList, setChartDataList] = useState([])
  const [availableBookmakers, setAvailableBookmakers] = useState([])
  const [selectedBookmakers, setSelectedBookmakers] = useState(['fanduel'])
  const [betType, setBetType] = useState('Moneyline')
  



  const [selectedStat, setSelectedStat] = useState(null)
  const [selectedStatData, setSelectedStatData] = useState(null)
  const [odds, setOdds] = useState([])
  const [gameProps, setGameProps] = useState(null)

  const [isDictionaryOpen, setDictionaryOpen] = useState(false)
  const [preferredBookmaker, setPreferredBookmaker] = useState('fanduel');
  const [expandedNodes, setexpandedNodes] = useState(['root'])
  
  const auth = useAuth()

  function addStat(ref, index) {
    let i = myLists.findIndex((s) => s.sport === sport )
    const statExists = myLists[i].contents[list].stats.some(([statRef, statIndex])  => {
      return JSON.stringify(statRef) === JSON.stringify(ref) && statIndex === index;
    });
    if (statExists) {
      setMyLists((prev) => {
        const newLists = [...prev];
        newLists[i].contents[list].stats = newLists[i].contents[list].stats.filter(([statRef, statIndex]) => {
          return JSON.stringify(statRef) !== JSON.stringify(ref) || statIndex !== index;
        });
        return newLists;
      });
      return;
    } else {
      setMyLists((prev) => {
        const newLists = [...prev];
        newLists[i].contents[list].stats = [...newLists[i].contents[list].stats, [ ref, index ]];
        return newLists;
      });
    }
  }

  const fetchFromAPI = async (endpoint, method = 'GET', data = {}) => {
    console.log(endpoint, method, data)
    data.user = auth.user ? auth.user.id : null;
    try {
      const options = {
        method,
        headers: { 'Content-Type': 'application/json' },
        body: method !== 'GET' ? JSON.stringify(data) : undefined,
      };
      const response = await fetch(`${apiUrl}/${endpoint}`, options);
      if (!response.ok) throw new Error('Network response was not ok')
      if (endpoint === 'userCollections') return response
      else return response.json();
    } catch (error) {
      console.error(`Error fetching ${endpoint}:`, error);
    }
  };

  //Fetch the leagues from the API and set the state.
  useEffect(() => {
    const fetchLeagues = async () => {
      try {
        const _leagues = await fetchFromAPI('v3/list');
        setLeagues(_leagues);
        if (localStorage.getItem('activeLeague')) setActiveLeague(localStorage.getItem('activeLeague'))
      } catch (error) {
        console.error('Error fetching leagues:', error);
      }
    };
    fetchLeagues();
  }, []);
  
  //If activeLeague changes, update the dictionary and get the games list.
  useEffect(() => {
     if (activeLeague && auth.user) {

      setSelectedStat(null);
      setSelectedStatData(null)
      setOdds([])
      setAvailableBookmakers([])
      setGame(null)
      setGameData(null)
      
      //set activeLeague to localstorage
      localStorage.setItem('activeLeague', activeLeague)

      const fetchDictionary = async () => {
        try {
          const _dictionary = await fetchFromAPI('v3/dictionary', 'POST', { league: activeLeague });
          setDictionary(_dictionary[0])
          setSport(_dictionary[1])
        } catch (error) {
          console.error('Error fetching dictionary:', error)
        }
      }
      fetchDictionary();


    }
  }, [activeLeague, auth.user])
  
  useEffect(() => {
    setGameData(null)
    const fetchGameData = async () => {
      let i = myLists.findIndex((s) => s.sport === sport )
      let home = game.home ? game.home.teamName : game.homeTeamDetails.teamName
      let visitor = game.visitor ? game.visitor.teamName : game.visitorTeamDetails.teamName
      if (myLists[i].contents[list].stats.length > 0) {
        let g = await fetchFromAPI('v3/gameData', 'POST', { 
          league: activeLeague, 
          sport: sport, 
          gamedate: game.home ? game.eventTime : game.gamedate, 
          home: home, 
          visitor: visitor, 
          stats: myLists[i].contents[list].stats
        })
          setAvailableBookmakers(g[1])
          setGameData(g[0])
      } else {
          let g = await fetchFromAPI('v3/gameData', 'POST', { 
            league: activeLeague, 
            sport: sport, 
            gamedate: game.home ? game.eventTime : game.gamedate, 
            home: home, 
            visitor: visitor, 
            stats: []
          })
            setAvailableBookmakers(g[1])
            setGameData(g[0])
          console.log('Not Data To Fetch')
      }
    }
    console.log(game && activeLeague, game, activeLeague)
    if (game && activeLeague) {
      fetchGameData();
    }
  }, [game, list, myLists])

 

  // //IF the gameProps or activeCollection changes, update the gameData.
  // useEffect(() => {
  //   const dataToSend = { league: activeLeague, game: gameProps, filter: activeCollection };

  //   const fetchGameDetails = async () => {
  //     const gameDetails = await fetchFromAPI(`odds/game`, "POST", dataToSend)
  //     let bookmakersArray = []
  //     let bookmakersToProcess = Object.keys(gameDetails.odds[0]);
  //     bookmakersToProcess.forEach((bookmaker) => {
  //       if (bookmaker !== 'timestamp') {
  //         bookmakersArray.push(bookmaker);
  //       }
  //     });
  //     console.log(gameData)
  //     setAvailableBookmakers(bookmakersArray);
  //     setGameData(gameDetails);
        
  //   };

  //   // Regular expression to match UUID format
  //   const uuidFormat = /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;
  //   let intervalId = null;

  //   if (gameProps && uuidFormat.test(gameProps)) {
  //     fetchGameDetails(); // Initial call before setting interval
  //     intervalId = setInterval(fetchGameDetails, 60000); // Set interval to 1 minute
  //   }

  //   // Cleanup function to clear the interval when gameProps changes or component unmounts
  //   return () => {
  //     if (intervalId) {
  //       clearInterval(intervalId);
  //     }
  //   };
  // }, [gameProps, activeCollection, activeLeague])

  // // If any of: [activeCollection, activeCollectionName, activeCollectionDescription]
  // // change, setMyCollections to update the collection data
  // useEffect(() => {
  //   if (!activeCollectionName || !activeCollectionDescription || myCollections.length <1 ) return;
  //   let newCollections = myCollections.map((collection) => {
  //     if (collection.stats === activeCollection) {
  //       return {
  //         name: activeCollectionName,
  //         stats: activeCollection,
  //         description: activeCollectionDescription,
  //       };
  //     } else {
  //       return collection;
  //     }
  //   });
  //   setMyCollections(newCollections);
  // }, [activeCollectionName, activeCollectionDescription]);

  // useEffect(() => {
  //   if (!activeCollectionName || !activeCollectionDescription || myCollections.length <1 ) return;
  //   let newCollections = myCollections.map((collection) => {
  //     if (collection.name === activeCollectionName) {
  //       return {
  //         name: activeCollectionName,
  //         stats: activeCollection,
  //         description: activeCollectionDescription,
  //       };
  //     } else {
  //       return collection;
  //     }
  //   });
  //   setMyCollections(newCollections);
  // }, [activeCollection]);
  
  // useEffect(() => {
  //   const debounceFetchFromAPI = _.debounce((collections) => {
  //     if (auth.user && activeLeague) {
  //       fetchFromAPI('userCollections', 'POST', {
  //           league: activeLeague,
  //           user: auth.user.id,
  //           collections,
  //         })
  //         .catch((error) => {
  //           console.error('Error updating user collections:', error);
  //         })
  //     }
  //   }, 1000);
  
  //   debounceFetchFromAPI(myCollections);
  
  //   return () => {
  //     debounceFetchFromAPI.cancel();
  //   };
  // }, [myCollections, auth.user, activeLeague]);

  return (
    <DashboardContext.Provider value={{
      fetchFromAPI, freeRows, leagues, setLeagues,
      activeLeague, setActiveLeague,
      game, setGame,
      list, setList,
      myLists, setMyLists,
      addStat,
      chartDataList, setChartDataList,
      availableBookmakers, setAvailableBookmakers,
      selectedBookmakers, setSelectedBookmakers,
      betType, setBetType,
      selectedStat, setSelectedStat,
      selectedStatData, setSelectedStatData,
      odds, setOdds,
      gameProps, setGameProps,
      gameData, setGameData,
      
      isDictionaryOpen, setDictionaryOpen,
      dictionary, setDictionary,
      preferredBookmaker, setPreferredBookmaker,
      expandedNodes, setexpandedNodes,
      sport, setSport,
    }}>
      {children}
    </DashboardContext.Provider>
  );
};
