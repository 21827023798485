import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { TextField, IconButton, Paper, Typography, Tooltip } from '@material-ui/core';
import { Edit as EditIcon, Delete as DeleteIcon, AddCircle } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  stickyNoteContainer: {
    padding: theme.spacing(1),
    backgroundColor: '#fffcab', // Sticky note color
    borderRadius: theme.shape.borderRadius,
    width: '100%',
    height: '100%',
    overflow: 'auto',
    // maxWidth: '400px',
    margin: 'auto',
  },
  textField: {
    // marginBottom: theme.spacing(.5),
    // backgroundColor: 'white',
    backgroundColor: '#fff9c4', // Slightly lighter sticky note color
    color: '#000000',
    borderRadius: theme.shape.borderRadius,
  },
  addButton: {
    // marginTop: theme.spacing(1),
    color: theme.palette.primary.main,
  },
  notesContainer: {
    marginTop: theme.spacing(.5),
  },
  note: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: theme.spacing(.5),
    color: '#000000',
    backgroundColor: '#fff9c4', // Slightly lighter sticky note color
  },
  noteActions: {
    display: 'flex',
    alignItems: 'center',
},
editIcon: {
    color: theme.palette.info.main,
},
deleteIcon: {
    color: theme.palette.error.main,
},
addIcon: {
    color: 'green',
    fontSize: '2rem', // Correct way to set a large font size
    display: 'flex',  // Ensure the icon is flexbox-aligned
    alignItems: 'center',
  },
}));

const StickyNote = () => {
  const classes = useStyles();
  const [text, setText] = useState('');
  const [notes, setNotes] = useState([]);
  const [editIndex, setEditIndex] = useState(null);

  useEffect(() => {
    // Load notes from local storage on component mount
    const savedNotes = JSON.parse(localStorage.getItem('stickyNotes')) || [];
    setNotes(savedNotes);
  }, []);

  useEffect(() => {
    // Save notes to local storage whenever notes change
    localStorage.setItem('stickyNotes', JSON.stringify(notes));
  }, [notes]);

  const handleInputChange = (e) => {
    if (e.target.value.length <= 100) {
      setText(e.target.value);
    }
  };

  const handleSubmit = () => {
    if (text.trim()) {
      const newNote = { text, timestamp: new Date().toLocaleString() };
      if (editIndex !== null) {
        const updatedNotes = notes.map((note, index) =>
          index === editIndex ? newNote : note
        );
        setNotes(updatedNotes);
        setEditIndex(null);
      } else {
        setNotes([...notes, newNote]);
      }
      setText('');
    }
  };

  const handleEdit = (index) => {
    setText(notes[index].text);
    setEditIndex(index);
  };

  const handleDelete = (index) => {
    setNotes(notes.filter((_, i) => i !== index));
    if (editIndex === index) {
      setEditIndex(null);
      setText('');
    }
  };

  return (
    <Paper className={classes.stickyNoteContainer}>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <TextField
          className={classes.textField}
          variant="outlined"
          placeholder="Write your note..."
          value={text}
          onChange={handleInputChange}
          onKeyDown={(e) => e.key === 'Enter' && handleSubmit()} // Use onKeyDown instead of onKeyPress
          fullWidth
        />
        <Tooltip title="Add Note">
          <IconButton onMouseDown={(e) => {e.stopPropagation()}} onClick={handleSubmit} className={classes.addButton}>
            <AddCircle className={classes.addIcon} />
          </IconButton>
        </Tooltip>
      </div>

      <div className={classes.notesContainer}>
        {notes.map((note, index) => (
          <Paper key={index} className={classes.note}>
            <Typography variant="body1">{note.text}</Typography>
            {/* Timestamp is omitted as per your instruction */}
            <div className={classes.noteActions}>
              <IconButton onMouseDown={(e) => {e.stopPropagation()}} onClick={() => handleEdit(index)} className={classes.editIcon}>
                <EditIcon />
              </IconButton>
              <IconButton onMouseDown={(e) => {e.stopPropagation()}} onClick={() => handleDelete(index)} className={classes.deleteIcon}>
                <DeleteIcon />
              </IconButton>
            </div>
          </Paper>
        ))}
      </div>
    </Paper>
  );
};

StickyNote.propTypes = {
  maxCharacters: PropTypes.number,
};

StickyNote.defaultProps = {
  maxCharacters: 100,
};

export default StickyNote;
