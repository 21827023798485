import React, { useState, useContext, useEffect } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Typography } from '@material-ui/core';
import DragIndicatorIcon from '@material-ui/icons/DragIndicator';
import { useAuth } from "../util/auth.js";
import { DashboardContext } from "./v3Context.js";
import { makeStyles } from '@material-ui/core/styles';
import DataRow from './DataRow.jsx';
import BarChartIcon from '@material-ui/icons/BarChart';
import ShowChartIcon from '@material-ui/icons/ShowChart';
import TrendingUpIcon from '@material-ui/icons/TrendingUp';
import EqualizerIcon from '@material-ui/icons/Equalizer';
import MultilineChartIcon from '@material-ui/icons/MultilineChart';



const useStyles = makeStyles((theme) => ({
  tableContainer: {
    width: '100%',
    height: '100%',
    maxHeight: '100%',
    overflow: 'auto',
  },
  strikeOutText: {
    textDecoration: 'line-through',
    color: theme.palette.text.disabled,
  },
  stickyHeader: {
    position: 'sticky',
    top: 0,
    backgroundColor: theme.palette.background.paper,
  },
  headerCell: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 5, 0, 0),
  },
  iconContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    // margin: theme.spacing(.5), // Add space below icons
    marginRight: theme.spacing(4), // Add space to the right of icons
  },
  icon: {
    margin: theme.spacing(1), // Add space below icons
    marginLeft: theme.spacing(1), // Add space between icons
  },
  teamNames: {
    margin: theme.spacing(1), // Add space below icons
    marginRight: theme.spacing(0), // Add space below team names
  },
  chartLabel: {
    margin: theme.spacing(0), // Add space below icons
    // alignSelf: 'flex-end', // Align 'Chart' text to the right
  },
  comparisonTableRow: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    '&:hover': {
      backgroundColor: theme.palette.action.hover,
    },
  },
  cell: {
    textAlign: 'center',
    padding: theme.spacing(1),
  },
  dragHandle: {
    cursor: 'grab',
    // padding: theme.spacing(1),
  },
  dragHandleCell: {
    width: 'auto',
    border: 'none',
    // flexShrink: 0,
    padding: theme.spacing(1),
  },
  dataRowCell: {
    flex: 1,
    width: '100%',
    padding: theme.spacing(0),
    border: 'none',
  },
}));

const DragHandle = ({ ...props }) => {
  const classes = useStyles();
  
  const onMouseDown = (e) => {
    // Stop propagation of the mousedown event
    e.stopPropagation();
  };

  return (
    <div {...props} onMouseDown={onMouseDown}>
      <DragIndicatorIcon className={classes.dragHandle} />
    </div>
  );
};



const ComparisonTable = () => {
  const classes = useStyles();
  const { myLists, setMyLists, setList, list, game, setGame, gameData, setGameData, dictionary } = useContext(DashboardContext);
  const [tableData, setTableData] = useState([]);
  const auth = useAuth();
  const [view, setView] = useState(0);



  useEffect(() => {
    if (gameData) {
      setTableData(gameData);
    }
  }, [gameData]);

  function findStatByPath(path) {
    let result = dictionary;
    for (let i = 0; i < path.length; i++) {
      const element = path[i];
      try {
        let folder = result.find((item) => item.name === element);
        if (folder) {
          result = folder.contents;
        } else {
          let stat = result.find((item) => item.statName === element);
          result = stat;
        }
      } catch (error) {
        console.log('Error findStatByPath', error);
        return null; // Return null or an appropriate fallback
      }
    }
    return result;
  }

  function viewHandler(e) {
    setView(e.currentTarget.id)
  }

  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }

    const newItems = Array.from(tableData);
    const [reorderedItem] = newItems.splice(result.source.index, 1);
    newItems.splice(result.destination.index, 0, reorderedItem);

    setTableData(newItems);
    setGameData((currentGameData) => ({ ...currentGameData, requestedStats: newItems }));
    setList(newItems.map((row) => row.id));
  };

  const DisplayValue = (value) => (Number(value) === value && value % 1 !== 0) ? value.toFixed(2) : value;
 
  return (
    <TableContainer component={Paper} className={classes.tableContainer}>
      <Table aria-label="comparison table">
        <TableHead>
          <TableRow className={classes.stickyHeader}>
            <TableCell className={classes.headerCell}>
              <div className={classes.iconContainer}>
                <EqualizerIcon alt="icon" className={parseInt(view) === 0 ? classes.selectedView : classes.unselectedView} onMouseDown={(e) => e.stopPropagation()} onClick={viewHandler} id={0} />
                <TrendingUpIcon alt="icon" className={parseInt(view) === 1 ? classes.selectedView : classes.unselectedView} onMouseDown={(e) => e.stopPropagation()} onClick={viewHandler} id={1}/>
                <MultilineChartIcon alt="icon" className={parseInt(view) === 2 ? classes.selectedView : classes.unselectedView} onMouseDown={(e) => e.stopPropagation()} onClick={viewHandler} id={2}/>
                <BarChartIcon alt="icon" className={parseInt(view) === 3 ? classes.selectedView : classes.unselectedView} onMouseDown={(e) => e.stopPropagation()} onClick={viewHandler} id={3}/>
              </div>
              <Typography className={classes.teamNames} variant="subtitle1" align="right">
                {game?.VisitorTeam?.teamName || 'Visitor'}
              </Typography>
              <Typography className={classes.teamNames} variant="subtitle1" align="right">
                -
              </Typography>
              <Typography className={classes.teamNames} variant="subtitle1" align="right">
                {game?.HomeTeam?.teamName || 'Home'}
              </Typography>
            </TableCell>
          </TableRow>
        </TableHead>
        {gameData ? (
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="list">
              {(provided) => (
                <TableBody ref={provided.innerRef} {...provided.droppableProps}>
                  {tableData.map((row, index) => {
                    if (!gameData[index] || !gameData[index].ref) {
                      console.warn(`Invalid data at index ${index}: `, gameData[index]);
                      return null; // Skip this item or handle it as needed
                  }
                    let entry = gameData[index];
                    let stat = findStatByPath(gameData[index].ref) || {}; // Provide a fallback
                    return (
                      <Draggable key={index} draggableId={`compTable-${row.id}`} index={index}>
                        {(provided) => (
                          <TableRow
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            className={classes.comparisonTableRow}
                          >
                            <TableCell className={classes.dragHandleCell}>
                              <DragHandle {...provided.dragHandleProps} />
                            </TableCell>
                            <TableCell className={classes.dataRowCell}>
                              <DataRow
                                view={view}
                                title={stat.statName}
                                visitorValue={DisplayValue(entry.VisitorValue)}
                                homeValue={DisplayValue(entry.HomeValue)}
                                reference={entry}
                                subtitle={stat.breadCrumb?.join(' / ') || 'Unknown Path'}
                                splitIndex={entry.index}
                              />
                            </TableCell>
                          </TableRow>
                        )}
                      </Draggable>
                    );
                  })}
                  {provided.placeholder}
                </TableBody>
              )}
            </Droppable>
          </DragDropContext>
        ) : (
          <TableBody>
            <TableRow>
              <TableCell colSpan={5} align="center">
                
                <Typography className={game ? classes.strikeOutText : null} variant="body1">Select Any Game From the Game Schedule</Typography>
                <Typography variant="body1">And</Typography>
                
                <Typography variant="body1">At Least 1 Stat from the Data Explorer</Typography>
                
              </TableCell>
            </TableRow>
          </TableBody>
        )}
      </Table>
    </TableContainer>
  );
};

export default ComparisonTable;