import React from "react";
import Navbar from "./../components/Navbar";
import AboutPage from "./about";
import IndexPage from "./index";
import FaqPage from "./faq";
import ContactPage from "./contact";
import PricingPage from "./pricing";
import DashboardPage from "./dashboard";
import AuthPage from "./auth";
import SettingsPage from "./settings";
import LegalPage from "./legal";
import LandingPage1 from "./landing1";
import { Switch, Route, Router, useLocation } from "./../util/router";
import PurchasePage from "./purchase";
import FirebaseActionPage from "./firebase-action";
import NotFoundPage from "./404";
import Footer from "./../components/Footer";
import Chat from "./../components/Chat";
import { AuthProvider } from "./../util/auth";
import { ThemeProvider } from "./../util/theme";
import { QueryClientProvider } from "./../util/db";
import { DataProvider } from "../v3/v3Context"; // Ensure correct import


function App(props) {
  
  
  return (
    <QueryClientProvider>
      <ThemeProvider>
        <AuthProvider>
          <DataProvider>
          <Chat />
          <Router>
            <>
              <Navbar
                color="default"
                logo='./images/statFactory-Logo.png'
                logoInverted="/images/statFactory-Logo-dark.png"
              />

              <Switch>
                <Route exact path="/" component={IndexPage} />
                <Route exact path="/landing1" component={LandingPage1} />
                <Route exact path="/about" component={AboutPage} />
                <Route exact path="/faq" component={FaqPage} />
                <Route exact path="/contact" component={ContactPage} />
                <Route exact path="/pricing" component={PricingPage} />
                <Route exact path="/dashboard" component={DashboardPage} />
                <Route exact path="/auth/:type" component={AuthPage} />
                <Route exact path="/settings/:section" component={SettingsPage}/>
                <Route exact path="/legal/:section" component={LegalPage} />
                <Route exact path="/purchase/:plan" component={PurchasePage} />
                <Route
                  exact
                  path="/firebase-action"
                  component={FirebaseActionPage}
                />
                <Route component={NotFoundPage} />
              </Switch>

                <Footer
                  size="normal"
                  bgImage=""
                  bgImageOpacity={1}
                  description="StatFactory.io is a sports data analytics platform that provides tools for sports bettors, fantasy sports players, and sports fans to make data-driven decisions."
                  copyright={`© ${new Date().getFullYear()} StatFactory.io`}
                  color="default"
                  logo='./images/statFactory-Logo.png'
                  logoInverted="/images/statFactory-Logo-dark.png"
                  sticky={true}
                />
            </>
          </Router>
          </DataProvider>
        </AuthProvider>
      </ThemeProvider>
    </QueryClientProvider>
  );
}

export default App;
