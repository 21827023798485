import React, { useState } from 'react';
import { TextField, Button, Typography, FormControl, FormControlLabel, RadioGroup, Radio, Container, Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

const useStyles = makeStyles((theme) => ({
    betCalculatorContainer: {
    margin: theme.spacing(0),
    height: '100%',
    overflow: 'auto',
    // padding: theme.spacing(0),
  },
  textField: {
    margin: theme.spacing(0),
  },
  calculateButton: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(0),
  },
  radioGroup: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
}));

const BetCalculator = () => {
  const classes = useStyles();
  const [oddsType, setOddsType] = useState('american');
  const [stake, setStake] = useState('');
  const [odds1, setOdds1] = useState('');
  const [odds2, setOdds2] = useState('');
  const [results, setResults] = useState(null);

  const handleCalculate = () => {
    const decimalOdds1 = oddsType === 'american' ? convertAmericanToDecimal(odds1) : parseFloat(odds1);
    const decimalOdds2 = oddsType === 'american' ? convertAmericanToDecimal(odds2) : parseFloat(odds2);
    
    const result1 = calculateResults(decimalOdds1, stake);
    const result2 = odds2 ? calculateResults(decimalOdds2, stake) : null;
    const vig = calculateVig(decimalOdds1, decimalOdds2);

    setResults({ result1, result2, vig });
  };

  const convertAmericanToDecimal = (odds) => {
    odds = parseFloat(odds);
    if (odds > 0) {
      return (odds / 100) + 1;
    } else {
      return (100 / Math.abs(odds)) + 1;
    }
  };

  const calculateResults = (decimalOdds, stake) => {
    const payout = stake * decimalOdds;
    const profit = payout - stake;
    const roi = (profit / stake) * 100;
    const impliedProbability = (1 / decimalOdds) * 100;

    return {
      payout: payout.toFixed(2),
      profit: profit.toFixed(2),
      roi: roi.toFixed(2),
      impliedProbability: impliedProbability.toFixed(2),
    };
  };

  const calculateVig = (decimalOdds1, decimalOdds2) => {
    if (!decimalOdds2) return null;
    const impliedProbability1 = 1 / decimalOdds1;
    const impliedProbability2 = 1 / decimalOdds2;
    const vigPercentage = (impliedProbability1 + impliedProbability2 - 1) * 100;

    return vigPercentage.toFixed(2);
  };

  return (
    <Paper elevation={3} className={classes.betCalculatorContainer}>
      <Container>
        <Typography variant="h5" gutterBottom>
          Bet Calculator
        </Typography>
        <FormControl component="fieldset">
          <RadioGroup row value={oddsType} onChange={(e) => setOddsType(e.target.value)} className={classes.radioGroup}>
            <FormControlLabel onMouseDown={(e) => e.stopPropagation()} value="american" control={<Radio />} label="American" />
            <FormControlLabel onMouseDown={(e) => e.stopPropagation()} value="decimal" control={<Radio />} label="Decimal" />
          </RadioGroup>
        </FormControl>
        <TextField
          label="Stake ($)"
          value={stake}
          onMouseDown={(e) => e.stopPropagation()}
          onChange={(e) => setStake(e.target.value)}
          fullWidth
          className={classes.textField}
        />
        <TextField
          label="Odds (Team 1)"
          value={odds1}
          onMouseDown={(e) => e.stopPropagation()}
          onChange={(e) => setOdds1(e.target.value)}
          fullWidth
          className={classes.textField}
        />
        <TextField
          label="Odds (Team 2)"
          value={odds2}
          onMouseDown={(e) => e.stopPropagation()}
          onChange={(e) => setOdds2(e.target.value)}
          fullWidth
          className={classes.textField}
        />
        <Button variant="contained" color="primary" onMouseDown={(e) => e.stopPropagation()} onClick={handleCalculate} className={classes.calculateButton}>
          Calculate
        </Button>
        {results && (
          <div>
            <Typography variant="h6">Team 1</Typography>
            <Typography>Pays: ${results.result1.payout}</Typography>
            <Typography>Returns: {results.result1.roi}%</Typography>
            <Typography>Imp %P {results.result1.impliedProbability}%</Typography>
            {results.result2 && (
              <>
                <Typography variant="h6">Team 2</Typography>
                <Typography>Pays: ${results.result2.payout}</Typography>
                <Typography>Returns: {results.result2.roi}%</Typography>
                <Typography>Imp %P: {results.result2.impliedProbability}%</Typography>
                <Typography variant="h6">Vig: {results.vig}%</Typography>
              </>
            )}
          </div>
        )}
      </Container>
    </Paper>
  );
};

BetCalculator.propTypes = {
  odds: PropTypes.string,
  oddsType: PropTypes.string,
  stake: PropTypes.string,
  payout: PropTypes.string,
  profit: PropTypes.string,
  roi: PropTypes.string,
};

export default BetCalculator;
