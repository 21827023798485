import React, { useState, useEffect, useContext, useRef } from 'react';
import { DashboardContext } from './v3Context.js';
import {
    LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer,
    Legend
} from 'recharts';
import { makeStyles } from '@material-ui/core/styles';
import { Switch, Typography, Box, Paper } from '@material-ui/core';
import { DragDropContext } from 'react-beautiful-dnd';

const IndexReference = (index) => {
    switch (index) {
      case 0:
        return 'Season Total'
      case 1:
        return 'Season by Away / Home'
      case 2:
        return 'Season by Underdog / Favorite'
      case 3:
        return 'Season by Conf. / Non-Conf.'
      case 4:
        return 'Last 3 Games Total'
      case 5:
        return 'Last 3 Games by Away / Home'
      case 6:
        return 'Last 3 Games by Underdog / Favorite'
      case 7:
        return 'Last 3 Games by Conf. / Non-Conf.'
      default:
        return ''
    }
  };

  const useStyles = makeStyles((theme) => ({
    chartContainer: {
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        height: '100%',
      },
      chartWrapper: {
        flexGrow: 1,
        position: 'relative',
      },
    toggleWrapper: {
        display: 'flex',
        flexDirection: 'column', // Stack toggles vertically
        padding: theme.spacing(0),
    },
    chartToggle: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        margin: theme.spacing(0, 0),
        flexWrap: 'wrap', // Ensure that content wraps instead of extending outside
    },
    switchBase: {
        '&$checked': {
          color: '#4caf50', // Green color when checked
        },
        '&$checked + $track': {
          backgroundColor: '#4caf50', // Green track when checked
        },
      },
      checked: {},
      track: {},
}));

const StatLineChart = () => {
    const classes = useStyles();
    const { chartDataList, fetchFromAPI, game, activeLeague } = useContext(DashboardContext);
    const [chartData, setChartData] = useState([]);
    const [activeKeys, setActiveKeys] = useState([]);
    const [isResizing, setIsResizing] = useState(false);
    const chartContainerRef = useRef(null);
    const [containerHeight, setContainerHeight] = useState(null);
    
    useEffect(() => {
        const resizeObserver = new ResizeObserver((entries) => {
          for (let entry of entries) {
            setContainerHeight(entry.contentRect.height);
          }
        });
    
        if (chartContainerRef.current) {
          resizeObserver.observe(chartContainerRef.current);
        }
    
        return () => {
          if (chartContainerRef.current) {
            resizeObserver.unobserve(chartContainerRef.current);
          }
        };
      }, []);

    useEffect(() => {
        if (!game || chartDataList.length === 0) {
            setChartData([]);
            return;
        }
        const fetchChartData = async () => {
            const endpoint = 'v3/chartData';
            const method = 'POST';
            const data = {
                league: activeLeague,
                visitor: game.visitor.teamName,
                home: game.home.teamName,
                data: chartDataList,
                gamedate: game.gamedate,
            };
            try {
                const response = await fetchFromAPI(endpoint, method, data);
                setChartData(response || []);
                setActiveKeys(Object.keys(response[0]).filter((key) => key !== 'Game'));
            } catch (error) {
                console.error('Error fetching chart data:', error);
                setChartData([]);
            }
        };

        fetchChartData();
    }, [chartDataList, game, activeLeague, fetchFromAPI]);

    const colorPalette = ['#FF6B6B', '#4ECDC4', '#45B7D1', '#FFA07A', '#98D8C8'];

    // Toggle the visibility of each line in the chart
    const handleToggleLine = (dataKey) => {
        setActiveKeys((prevKeys) =>
            prevKeys.includes(dataKey) ? prevKeys.filter((key) => key !== dataKey) : [...prevKeys, dataKey]
        );
    };

    // Function to handle drag start (detach resize observer)
    const handleDragStart = () => {
        setIsResizing(true);
    };

    // Function to handle drag end (reattach resize observer)
    const handleDragEnd = () => {
        setIsResizing(false);
    };

    if (chartData.length === 0) {
        return (
            <Box component={Paper} className={classes.paperContainer}>
                <Typography variant="h6" align="center">
                    Select Data to display from Team Comparison
                </Typography>
            </Box>
        );
    }

    const keys = Object.keys(chartData[0]).filter((key) => key !== 'Game');

    return (
        <DragDropContext
            onDragStart={handleDragStart}
            onDragEnd={handleDragEnd}
        >
            <div className={classes.chartContainer} ref={chartContainerRef}>
            <div className={classes.toggleWrapper}>
            {keys.map((key, index) => {
                let label = key.split('-');
                let i = label.pop();
                let ref = IndexReference(parseInt(i));
                label.splice(label.length - 1, 0, ref);
                label = label.join(' ');

                return (
                    <div key={index} className={classes.chartToggle}>
                        <Switch
                        classes={{
                            switchBase: classes.switchBase,
                            checked: classes.checked,
                            track: classes.track,
                        }}
                        checked={activeKeys.includes(key)}
                        onChange={() => handleToggleLine(key)}
                        onMouseDown={(e) => e.stopPropagation()}
                        />
                        <Typography variant="body1" align="left" style={{ color: colorPalette[index % colorPalette.length] }}>
                            {label}
                        </Typography>
                    </div>
                        );
            })}
            </div>
            <div className={classes.chartWrapper}>
                {!isResizing && (
                    <ResponsiveContainer width="96%" height="100%">
                        <LineChart
                            data={chartData}
                            margin={{ top: 5, right: 5, left: 5, bottom: 5 }}
                        >
                            {/* <Legend verticalAlign="top" height={36} /> */}
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="Game" />
                            <YAxis />
                            <Tooltip />
                            {keys.map((key, index) =>
                                activeKeys.includes(key) && (
                                    <Line
                                        key={key}
                                        stroke={colorPalette[index % colorPalette.length]}
                                        type="natural"
                                        dataKey={key}
                                        strokeWidth={3}
                                        dot={false}
                                        activeDot={{ r: 8, strokeWidth: 2, stroke: '#fff' }}
                                    />
                                )
                            )}
                        </LineChart>
                        
                    </ResponsiveContainer>
                )}
            </div>
            </div>
        </DragDropContext>
    );
};

export default StatLineChart;