import React, { useState, useEffect, useContext } from 'react';
import { DashboardContext } from './v3Context.js';
import {
  LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer,
} from 'recharts';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Box, Paper,
  Select, MenuItem, InputLabel, FormControl, Checkbox, ListItemText
 } from '@material-ui/core';
import moment from 'moment';

const useStyles = makeStyles((theme) => ({
  chartContainer: {
    padding: theme.spacing(0),
    width: '100%',
    height: '100%',
    overflow: 'auto',
  },
  paperContainer: {
    padding: theme.spacing(1),
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[1],
  },
  chart: {
    padding: theme.spacing(0),
    width: '100%',
  },
  OddsSelectionDropDown: {
    display: 'flex',
    flexDirection: 'row-wrap',
    alignItems: 'space-between',
    border: 'none',
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(0.5),
    margin: theme.spacing(0.5),
    cursor: 'pointer',
    textAlign: 'center',
    boxShadow: theme.shadows[5],
    // backgroundColor: theme.palette.type === 'dark' ? theme.palette.grey[800] : '#FFFFFF',
  },
  select: {
    backgroundColor: theme.palette.type === 'dark' ? theme.palette.grey[800] : '#FFFFFF',
  },
  OddsSelectionDropDownSelect: {
    width: '50%',
    minWidth: 200,
    padding: theme.spacing(1), // Add padding to the Select component
  },
  inputLabel: {
    paddingTop: theme.spacing(1), // Add padding to the left of the InputLabel
    paddingLeft: theme.spacing(1), // Add padding to the left of the InputLabel
  },
}));

const OddsDropdown = ({setSelectedBookmakers, selectedBookmakers}) => {
  const classes = useStyles();
  const { setBetType, betType, availableBookmakers } = useContext(DashboardContext);
  const betOptions = ['Moneyline', 'Spread', 'Over-Under'];
  const booksOptions = availableBookmakers;
  const handleBetChange = (event) => {
    setBetType(event.target.value);
  };

  const handleBooksChange = (event) => {
    const {
      target: { value },
    } = event;
    setSelectedBookmakers(
      typeof value === 'string' ? value.split(',') : value,
    );
  };

  // useEffect to reset selectedBooks when availableBookmakers changes
  useEffect(() => {
    // Reset selectedBooks to an empty array or filter based on the new availableBookmakers
    setSelectedBookmakers((prevSelectedBookmakers) =>
      prevSelectedBookmakers.filter((book) => availableBookmakers.includes(book))
    );
  }, [availableBookmakers]);

  return (
    <div >
      {/* Single Select Dropdown */}
        <FormControl variant="filled" className={classes.OddsSelectionDropDownSelect}>
        <InputLabel className={classes.inputLabel}>Bet Type</InputLabel>
        <Select className={classes.select} value={betType} onChange={handleBetChange} label="Bet Type">
          {betOptions.map((option) => (
            <MenuItem key={option} value={option}>
              {option}
            </MenuItem>
          ))}
        </Select>
        </FormControl>

      {/* Multi-Select Dropdown */}
      <FormControl variant="filled" className={classes.OddsSelectionDropDownSelect}>
        <InputLabel className={classes.inputLabel}>Sportsbooks</InputLabel>
        <Select
        className={classes.select}
          multiple
          value={selectedBookmakers}
          onChange={handleBooksChange}
          renderValue={(selected) => selected.join(', ')}
        >
          {booksOptions.map((book) => (
            <MenuItem key={book} value={book}>
              <Checkbox checked={selectedBookmakers.indexOf(book) > -1} />
              <ListItemText primary={book} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
}

const OddsChart = () => {
  const classes = useStyles();
  const { fetchFromAPI, game, activeLeague, betType, selectedBookmakers, setSelectedBookmakers } = useContext(DashboardContext);
  const [oddsData, setOddsData] = useState([]);

  useEffect(() => {
    if (!game || selectedBookmakers.length === 0) {
      setOddsData([]);
      return;
    }
  
    const fetchOddsData = async () => {
      const endpoint = 'v3/oddsData';
      const method = 'POST';
      const data = {
        league: activeLeague,
        game: game,
        type: betType,
        bookmakers: selectedBookmakers,
      };
      console.log('fetchOddsData:', data);
  
      try {
        const response = await fetchFromAPI(endpoint, method, data);
        setOddsData(response);
      } catch (error) {
        console.error("Error fetching chart data:", error);
        setOddsData([]);
      }
    };
  
    // Add a conditional check to avoid unnecessary API calls
    if (selectedBookmakers && selectedBookmakers.length > 0) {
      fetchOddsData();
    }
  }, [game, activeLeague, fetchFromAPI, selectedBookmakers, betType]);

  const colorPalette = ['#FF6B6B', '#4ECDC4', '#45B7D1', '#FFA07A', '#98D8C8'];
  if (!game) {
    console.log('No game', game)
    return (
      <Box component={Paper} className={classes.paperContainer}>
        <Typography variant="h6" align="center">
          Select A Game From Game Schedule
        </Typography>
      </Box>
    );
  } else if (oddsData.length === 0) {
    console.log('No Data', oddsData)
    return (
      <Box component={Paper} className={classes.paperContainer}>
        <OddsDropdown selectedBookmakers={selectedBookmakers} setSelectedBookmakers={setSelectedBookmakers}/>
        <Typography variant="h6" align="center">
          No Data Available
        </Typography>
      </Box>
    );
  } else {
  // Transform data for Recharts
  const transformDataForChart = (bookmakerData) => {
    const allData = {};

    bookmakerData.forEach(bookmaker => {
      bookmaker.data.forEach(point => {
        const { timestamp, ...rest } = point;

        // Initialize the data point if it doesn't exist
        if (!allData[timestamp]) {
          allData[timestamp] = { timestamp };
        }

        // Add each attribute dynamically, prefixing it with the bookmaker name
        Object.keys(rest).forEach(attr => {
          allData[timestamp][`${bookmaker.bookmaker}_${attr}`] = rest[attr];
        });
      });
    });

    // Convert the object back into an array of data points
    return Object.values(allData);
  };

  let chartData = transformDataForChart(oddsData);

  const fillMissingTimestamps = (data, interval = 60000) => {
    const filledData = [];
    
    // Convert each timestamp into a Date object for easier manipulation
    let previousPoint = data[0];
    let previousTime = new Date(previousPoint.timestamp).getTime();
    const endTime = new Date().getTime();

    // Iterate from the first time to the last time, filling in missing points
    while (previousTime <= endTime) {
      const nextPoint = data.find(point => new Date(point.timestamp).getTime() === previousTime);
      
      // If no exact match, push the previous known point but with the new time
      if (!nextPoint) {
        filledData.push({
          ...previousPoint,
          timestamp: previousTime, // Unix timestamp in milliseconds
        });
      } else {
        // Update the previousPoint to the current one if an exact match is found
        filledData.push({
          ...nextPoint,
          timestamp: new Date(nextPoint.timestamp).getTime(), // Ensure timestamp is in milliseconds
        });
        previousPoint = nextPoint;
      }

      previousTime += interval;
    }

    return filledData;
  };

  const keys = Object.keys(chartData[0]).filter(key => key !== 'timestamp');
  const filledData = fillMissingTimestamps(chartData.reverse());

  return (
    <Paper className={classes.chartContainer}>
      <OddsDropdown selectedBookmakers={selectedBookmakers} setSelectedBookmakers={setSelectedBookmakers}/>
    <ResponsiveContainer width="100%" height={200}>
      {/* <Box component={Paper} className={classes.paperContainer}> */}
        <LineChart
        data={filledData}
        margin={{ top: 10, right: 20, left: 0, bottom: 0 }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis
          dataKey="timestamp"
          type="number"
          domain={['dataMin', 'dataMax']}
          scale="time"
          tickFormatter={(time) => moment(time).format('HH:mm')}
          allowDuplicatedCategory={false}
        />
        <YAxis
          domain={[(dataMin) => (dataMin > -100 ? -110 : dataMin - 30), (dataMax) => (dataMax < 100 ? 110 : dataMax + 30)]}
          tickFormatter={(tick) => {
            // Only format ticks that are not near 0 (because American odds don't have 0)
            if (tick >= 100) {
              return `+${tick}`;
            } else if (tick <= -100) {
              return `${tick}`;
            } else {
              return ''; // Exclude values near 0 from rendering
            }
          }}
        />
        <Tooltip
          labelFormatter={(time) => moment(time).format('YYYY-MM-DD HH:mm:ss')}
        />
        {/* <Legend /> */}
        {keys.map((key, index) => (
          <Line
            key={key}
            type="monotone"
            dataKey={key}
            stroke={colorPalette[index % colorPalette.length]}
            strokeWidth={2}
            dot={false}
          />
        ))}
        </LineChart>
      {/* </Box> */}
    </ResponsiveContainer>
    </Paper>
  );

  }
};

export default OddsChart;
