import React, { useContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Box, Typography, Breadcrumbs } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { DashboardContext } from './v3Context.js';
import TimelineIcon from '@material-ui/icons/Timeline';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    border: '1px solid #ccc',
    borderRadius: theme.shape.borderRadius,
  },
  topPanel: {
    paddingLeft: theme.spacing(1),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'left',
    width: '100%',
  },
  bottomPanel: {
    width: '100%',
  },
  middlePanel: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'left',
    marginLeft: theme.spacing(1),
    flexGrow: 1,
  },
  titlePane: {
    paddingLeft: theme.spacing(2),
    width: '60%',
  },
  visitorPane: {
    width: '15%',
    textAlign: 'right',
  },
  homePane: {
    width: '15%',
    textAlign: 'right',
    marginRight: theme.spacing(4),
  },
  icon: {
    width: 30,
    height: 30,
    margin: theme.spacing(0),
    cursor: 'pointer',
  },
  icontoggled: {
    color: '#00FF00', // Bright green color for the icon
    filter: 'drop-shadow(0 0 5px #00FF00)', // Green glowing drop shadow
  },
  breadcrumbs: {
    flexGrow: 1,
  },
}));


const IndexReference = (index) => {
  switch (index) {
    case 0:
      return ['Season Total'];
    case 1:
      return ['Season by Away / Home'];
    case 2:
      return ['Season by Underdog / Favorite'];
    case 3:
      return ['Season bynference / Non-Conference'];
    case 4:
      return ['Last 3 Games Total'];
    case 5:
      return ['Last 3 Games by Away / Home'];
    case 6:
      return ['Last 3 Games by Underdog / Favorite'];
    case 7:
      return ['Last 3 Games by Conf. / Non-Conf.'];
    default:
      return [];
  }
};

const DataRow = ({ view, title, visitorValue, homeValue, splitIndex, reference }) => {
  const classes = useStyles();
  const { chartDataList, setChartDataList } = useContext(DashboardContext);
  const [showChart, setShowChart] = useState(false);
  const [visVal, setVisVal] = useState(visitorValue);
  const [homeVal, setHomeVal] = useState(homeValue);

  useEffect(() => {
    // Check if sendToChart is already in chartDataList when the component mounts or when chartDataList changes
    const sendToChart = [reference.ref, reference.index];
    const isInChart = chartDataList.some(
      (item) => JSON.stringify(item[0]) === JSON.stringify(sendToChart[0]) && item[1] === sendToChart[1]
    );
    setShowChart(isInChart);
  }, [chartDataList, reference])
  
  useEffect(() => {
    let visitorAverage, homeAverage;
    view = parseInt(view);
    if (view === 0) {
      setVisVal(visitorValue);
      setHomeVal(homeValue);
    } else if (view === 1) {
      visitorAverage = visitorValue / reference.VisitorGames;
      console.log('visitorAverage: ', visitorAverage)
      setVisVal(visitorAverage.toFixed(2));
      homeAverage = homeValue / reference.HomeGames;
      console.log('homeAverage: ', homeAverage)
      setHomeVal(homeAverage.toFixed(2));
    } else if (view === 2) {
      visitorAverage = (visitorValue - reference.CurrentContext.Min) / (reference.CurrentContext.Max - reference.CurrentContext.Min);
      setVisVal(visitorAverage.toFixed(2));
      homeAverage = (homeValue - reference.CurrentContext.Min) / (reference.CurrentContext.Max - reference.CurrentContext.Min);
      setHomeVal(homeAverage.toFixed(2));
    } else if (view === 3) {
      visitorAverage = (visitorValue - reference.CurrentContext.Mean) / reference.CurrentContext.StandardDeviation;
      setVisVal(visitorAverage.toFixed(2));
      homeAverage = (homeValue - reference.CurrentContext.Mean) / reference.CurrentContext.StandardDeviation;
      setHomeVal(homeAverage.toFixed(2));
    }

    // Optional: Clean up or debugging logs
    console.log('Updated values: ', visVal, homeVal);

}, [view, visitorValue, homeValue, reference]);


  const toggleToChart = () => {
    const sendToChart = [reference.ref, reference.index];
    const isInChart = chartDataList.some(
      (item) => JSON.stringify(item[0]) === JSON.stringify(sendToChart[0]) && item[1] === sendToChart[1]
    );

    let updatedList;
    if (isInChart) {
      updatedList = chartDataList.filter(
        (item) => !(JSON.stringify(item[0]) === JSON.stringify(sendToChart[0]) && item[1] === sendToChart[1])
      );
      setShowChart(false);
    } else {
      updatedList = [...chartDataList, sendToChart];
      setShowChart(true);
    }
    setChartDataList(updatedList);
  };
  
  const breadCrumb = IndexReference(splitIndex);
  return (
    <Box className={classes.container}>
      <Box className={classes.topPanel}>
        <Breadcrumbs className={classes.breadcrumbs}>
          <Typography
            style={{
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
            }}
            variant="body2"
          >
            {breadCrumb.join(' / ')}
          </Typography>
        </Breadcrumbs>
      </Box>
      <Box className={classes.bottomPanel}>
        <Box className={classes.middlePanel}>
            <TimelineIcon
              alt="icon"
              onMouseDown={(e) => e.stopPropagation()}
              onClick={toggleToChart}
              className={`${classes.icon} ${showChart ? classes.icontoggled : ''}`}
            />
          <div className={classes.titlePane}>
            <Typography variant="subtitle1">{title}</Typography>
          </div>

          <div className={classes.visitorPane}>
            <Typography variant="h6">
              { visVal }
            </Typography>
              </div>
          <div className={classes.homePane}>
            <Typography variant="h6">
              {homeVal}
            </Typography>
          </div>
        </Box>
      </Box>
    </Box>
  );
};


export default DataRow;
