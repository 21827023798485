import React, { useState, useEffect, useCallback, useContext } from "react";
import _ from "lodash";
import { Responsive, WidthProvider } from "react-grid-layout";
import { makeStyles, useTheme, withStyles } from '@material-ui/core/styles';
import { Icon, Button, Typography, Card } from '@material-ui/core';

import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';

import CancelIcon from '@material-ui/icons/Cancel'


import CasinoTwoToneIcon from '@material-ui/icons/CasinoTwoTone';
import AccountTreeTwoToneIcon from '@material-ui/icons/AccountTreeTwoTone';
import CompareTwoToneIcon from '@material-ui/icons/CompareTwoTone';
import NoteTwoToneIcon from '@material-ui/icons/NoteTwoTone';
import TodayTwoToneIcon from '@material-ui/icons/TodayTwoTone';
import CollectionsBookmarkTwoToneIcon from '@material-ui/icons/CollectionsBookmarkTwoTone';
import MoneyTwoToneIcon from '@material-ui/icons/MoneyTwoTone';
import InsertChartTwoToneIcon from '@material-ui/icons/InsertChartTwoTone';
import HealingTwoToneIcon from '@material-ui/icons/HealingTwoTone';

import GameSchedule from "./GameSchedule.jsx";
import ComparisonTable from "./ComparisonTable.jsx";
import DataExplorer from "./DataExplorer.jsx";
import BetCalculator from "./BetCalculator.jsx";
import LeagueSelect from "./LeagueSelect.jsx";
import CollectionManager from "./CollectionManager.jsx";
import StickyNote from "./StickyNote.jsx";
import StatLineChart from "./StatLineChart.jsx";
import OddsChart from "./OddsChart.jsx";
import Transactions from "./Transactions.jsx";


const ResponsiveReactGridLayout = WidthProvider(Responsive);

const useStyles = makeStyles((theme) => ({
  toolbox: {
    border: 'none',
    // padding: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  toolboxTitle: {
    // marginBottom: theme.spacing(1),
  },
  toolboxItems: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'center',
  },
  toolboxItem: {
    border: 'none',
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(1),
    margin: theme.spacing(.5),
    cursor: 'pointer',
    textAlign: 'center',
    boxShadow: theme.shadows[5],
    // backgroundColor: 'rgba(0, 128, 0, .5)',
    backgroundColor: theme.palette.type === 'dark' ? theme.palette.grey[800] : theme.palette.grey[200],
  },
  hideButton: {
    cursor: 'pointer',
    position: 'absolute',
    fontSize: '20px',
    top: '2px',
    right: '2px',
    zIndex: 1000,
  },
  edgeLabel: {
    position: 'absolute',         // Anchors the element
    top: 4,                       // Positions the div at the top
    left: 20,                // Moves the div 10px to the left
    fontStyle: 'italic',
    fontWeight: 'bold',
  },
  gridItem: {
    border: 'none',
    borderRadius: theme.shape.borderRadius,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    // margin: theme.spacing(2),
    padding: theme.spacing(3),
    backgroundColor: theme.palette.type === 'dark' ? theme.palette.grey[800] : theme.palette.grey[200],
    boxShadow: theme.shadows[5],
  },
  card: {
    width: '100%',
    height: '100%',
    backgroundColor: theme.palette.type === 'dark' ? theme.palette.grey[800] : theme.palette.grey[200],
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  LeagueSelect: {
    backgroundColor: 'rgba(0, 128, 0, .5) !important',
  },
  dashboardSelectContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    alignItems: 'center', // Optional: Align items vertically centered
    gap: theme.spacing(2), // Optional: Adjust spacing between items
  },
}));

function generateRandomString(length = 5) {
  return Math.random().toString(36).substring(2, 2 + length);
}

const ToolBoxItem = ({ item, onTakeItem }) => {
  const itemStyle = item.name === 'Select League' 
    ? { backgroundColor: 'rgba(0, 128, 0, .3)' } 
    : {};
  const classes = useStyles();
  return (
    <div 
      className={`${classes.toolboxItem} ${item.class}`}
      style={itemStyle}
      onClick={() => onTakeItem(item)}
    >
      <Icon>{item.icon}</Icon>
      <div>{item.name}</div>
    </div>
  );
};

const ToolBox = ({ items, onTakeItem }) => {
  const classes = useStyles();
  return (
    <div className={classes.toolbox}>
      <div className={classes.toolboxItems}>
       <LeagueSelect />
        {items.map(item => (
          <ToolBoxItem key={item.i} item={item} onTakeItem={onTakeItem} class={item.class}/>
        ))}
      </div>
    </div>
  );
};

const ToolboxLayout = (props) => {
  const classes = useStyles();
  
  const predefinedItems = [
    {
      i: 'gamesSchedule_',
      componentProperties: {
        itemLimit: 1,
      },
      x: 0,
      y: 0,
      w: 12,
      h: 11,
      static: false,
      name: 'Game Schedule',
      icon: <TodayTwoToneIcon />,
      component: <GameSchedule />
    },
    {
      i: 'exp_',
      componentProperties: {},
      x: 9,
      y: 0,
      w: 12,
      h: 25,
      static: false,
      name: 'Data Explorer',
      component: <DataExplorer />,
      icon: <AccountTreeTwoToneIcon />,
    },
    {
      i: 'mgr_',
      componentProperties: {},
      x: 4,
      y: 0,
      w: 4,
      h: 25,
      static: false,
      name: 'Collection Manager',
      component: <CollectionManager />,
      icon: <CollectionsBookmarkTwoToneIcon />,
    },
    {
      i: 'comp_',
      componentProperties: {},
      x: 0,
      y: 11,
      w: 9,
      h: 15,
      static: false,
      name: 'Team Comparison',
      component: <ComparisonTable />,
      icon: <CompareTwoToneIcon />,
    },
    {
      i: 'oddsChart_',
      componentProperties: {},
      x: 1,
      y: 1,
      w: 4,
      h: 10,
      static: false,
      name: 'Odds Chart',
      component: <OddsChart />,
      icon: <MoneyTwoToneIcon />,
    },
    {
      i: 'statsChart_',
      componentProperties: {},
      x: 1,
      y: 1,
      w: 10,
      h: 4,
      static: false,
      name: 'Stats Chart',
      icon: <InsertChartTwoToneIcon />,
      component: <StatLineChart />,
    },
    {
      i: 'transactions_',
      componentProperties: {},
      x: 6,
      y: 3,
      w: 10,
      h: 7,
      static: false,
      name: 'Transactions',
      icon: <HealingTwoToneIcon />,
      component: <Transactions />,
    },

    {
      i: 'betCalculator_',
      componentProperties: {},
      x: 16,
      y: 0,
      w: 4,
      h: 16,
      static: false,
      name: 'Wager Calculator',
      component: <BetCalculator />,
      icon: <CasinoTwoToneIcon />,
    },
    {
      i: 'stickynote_',
      componentProperties: {
        itemLimit: 1,
      },
      x: 19,
      y: 0,
      w: 5,
      h: 14,
      static: false,
      name: 'Notes',
      component: <StickyNote />,
      icon: <NoteTwoToneIcon/>,
    },
    // {
    //   i: 'teamStats_',
    //   componentProperties: {
    //     itemLimit: 3,
    //   },
    //   x: 1,
    //   y: 10,
    //   w: 2,
    //   h: 10,
    //   static: false,
    //   name: 'Team Stats',
    //   icon: <GroupIcon />,
    // },
    // {
    //   i: 'playerCard_',
    //   componentProperties: {},
    //   x: 1,
    //   y: 10,
    //   w: 2,
    //   h: 10,
    //   static: false,
    //   name: 'Player Card',
    //   icon: <PersonIcon />,
    // },
    // {
    //   i: 'playerCompositor',
    //   componentProperties: {},
    //   x: 1,
    //   y: 10,
    //   w: 2,
    //   h: 10,
    //   static: false,
    //   name: 'Player Compositor',
    //   icon: <CasinoIcon />,
    //   component: <GameSchedule />
    // },
   
  ];

  const [currentBreakpoint, setCurrentBreakpoint] = useState("lg");
  const [compactType, setCompactType] = useState("vertical");
  const [mounted, setMounted] = useState(false);
  const [layouts, setLayouts] = useState({ lg: [] });
  const [toolbox, setToolbox] = useState({ lg: predefinedItems });
  
  useEffect(() => {
    setMounted(true)
    setLayouts(JSON.parse(localStorage.getItem("layout")) || { lg:[], md:[], sm:[], xs:[], xxs:[]});
  }, []);

  const generateDOM = useCallback(() => {
    return _.map(layouts[currentBreakpoint], (l, index) => { 
      let componentComponent = l.i.split('_')[0];
      const selectedElement = predefinedItems.find(element => element.i.split('_')[0] === componentComponent);
      return (
      <div key={l.i} className={`${selectedElement.class} ${classes.gridItem} ${l.static ? "static" : ""}`}>
        <CancelIcon
          className={classes.hideButton}
          onMouseDown={(e) => e.stopPropagation()}
          onClick={(e) => {
            e.stopPropagation();
            onPutItem(l);
          }}
        />
        <div className={classes.edgeLabel}>{selectedElement.name}</div>
          {selectedElement.component}
      </div>
    )});
  }, [layouts, currentBreakpoint, classes]);

  const onBreakpointChange = breakpoint => {
    setCurrentBreakpoint(breakpoint);
    setToolbox(prevToolbox => ({
      ...prevToolbox,
      [breakpoint]: prevToolbox[breakpoint] || prevToolbox[currentBreakpoint] || []
    }));
  };

  const onCompactTypeChange = () => {
    setCompactType(oldCompactType =>
      oldCompactType === "horizontal" ? "vertical" : oldCompactType === "vertical" ? null : "horizontal"
    );
  };

  
  const handleResizeObserver = useCallback(_.debounce(() => {
    console.log('ResizeObserver loop completed with undelivered notifications.');
    // Your handler logic here
  }, 300), []);

  useEffect(() => {
    window.addEventListener('resize', handleResizeObserver);
    return () => {
      window.removeEventListener('resize', handleResizeObserver);
    };
  }, [handleResizeObserver]);

  const onPutItem = item => {
    item.i = item.i.split('_')[0] + '_'
    setLayouts(prevLayouts => ({
      ...prevLayouts,
      [currentBreakpoint]: prevLayouts[currentBreakpoint].filter(({ i }) => i !== item.i)
    }));
  };

  const onTakeItem = item => {
    item.i = (`${item.i}${generateRandomString()}`)
    console.log('Set', item)
    setLayouts(prevLayouts => ({
      ...prevLayouts,
      [currentBreakpoint]: [...prevLayouts[currentBreakpoint], item]
    }));
  };


  const onLayoutChange = (layout, layouts) => {
    // console.log('Layout changed', layouts[currentBreakpoint])
    localStorage.setItem("layout", JSON.stringify(layouts));
    setLayouts(layouts);
  };

  return (
    <div>
       <div>
        {/* Current Breakpoint: {currentBreakpoint} ({ResponsiveReactGridLayout.props.cols[currentBreakpoint]} columns) */}
      </div>
      
    
      <ToolBox
        items={toolbox[currentBreakpoint] || []}
        onTakeItem={onTakeItem}
        onCompactTypeChange={onCompactTypeChange}
      />
      <ResponsiveReactGridLayout
        {...props}
        layouts={layouts}
        onBreakpointChange={onBreakpointChange}
        onLayoutChange={onLayoutChange}
        measureBeforeMount={false}
        useCSSTransforms={mounted}
        compactType={compactType}
        preventCollision={!compactType}
      >
        {generateDOM()}
      </ResponsiveReactGridLayout>
    </div>
  );
};

ToolboxLayout.defaultProps = {
  className: "layout",
  rowHeight: 20,
  onLayoutChange: () => { },
  cols: { lg: 24, md: 20, sm: 12, xs: 4, xxs: 2 },
  initialLayout: JSON.parse(localStorage.getItem("layout")) || { lg:[], md:[], sm:[], xs:[], xxs:[]},
};

export default ToolboxLayout;
